<template>
    <div v-if="entries.length" class="pagination" ref="pagination">
        <span>Showing <b>{{ currentPage === 1 ? 1 : currentPage - 1 + '1'}}-{{ currentTotal }}</b> from <b>{{total}}</b></span>
        <img src="/assets/images/left-arrow.svg" alt="next_page" @click="handlePage('left')">
        <img src="/assets/images/right-arrow.svg" alt="previous_page" @click="handlePage('right')">
    </div>
</template>

<script>
export default {
    props: {
        entries: {
            type: Array
        },
        currentPage: {
            type: Number
        },
        total: {
            type: Number
        },
        perPage: {
            type: Number
        },
        pages: {
            type: Number
        }
    },
    computed: {
        currentTotal() {
            return this.total > 10 && (this.perPage * this.currentPage < this.total) ? (this.perPage * this.currentPage) : this.total
        },
    },
    methods: {
        handlePage(to) {
            switch (to) {
                case 'left':
                    if (this.currentPage > 1)  this.$emit('changePage', 'less')
                break;
                case 'right':
                    if (this.currentPage < this.pages)  this.$emit('changePage', 'more')
                break;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.pagination{
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    gap: 5px;
    margin-bottom: 10px;

    img{
      width: 8px;
      cursor: pointer;
    }

    .underline{
      text-decoration: underline;
    }
}
</style>