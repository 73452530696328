<template>
    <div class="selected-options">
        <div class="select" :class="{opened: open}">
            <span @click="open = !open" class="open">Actions</span>
        </div>
        <div class="options" v-if="open">
            <div class="option" v-for="(item,index) in items" :key="index" @mouseover="show[item.name] = true" @mouseleave="show[item.name] = false">
                <div class="opt-name">
                    <span @click="selectItem(item)">{{item.name}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
        required: true,
        type: Array,
        default: () => [],
        },
    },

    data() {
        return {
            open : false,
            show: {},
        }
    },

    methods: {
        selectItem(item) {
            this.$emit('selectedItem', [item])
            this.open = false
        },

        removeFilter() {
            this.$emit('selectedItem', ['remove'])
        }
    },

    mounted() {
        const isPromise = (promise) => {
            return !!promise && typeof promise.then === 'function'
        }

        this.items.forEach(element => {
            if(isPromise(element.options)) {
                Promise.resolve(element.options).then(value => element.options = value)
            }
        })
    }
}
</script>

<style scoped>
    .selected-options{
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 4;
    }

    .select.opened .open,
    .select.opened .remove{
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      color: black;
    }

    .select .open,
    .select .remove{
      border-radius: 5px;
      cursor: pointer;
      user-select: none;
      background: white;
      border: 1px solid #ced4da;
      color: #CAD6E2;
      padding: 10px 25px;
      transition: 0.2s;
      text-transform: capitalize;
    }

    .select .remove .remove-x {
        color: rgb(163, 10, 10);
    }

    .select .remove:hover{
        background: rgb(163, 10, 10);
        color: white;
    }
    .select .remove:hover > .remove-x{
        color: white;
    }

    .selected-options .options {
        position: absolute;
        top: 25px;
        width: 100%;
    }
    .selected-options .options .option {
        background: white;
        border: 1px solid #ced4da;
        border-top: none;
        display: flex;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        transition: 0.2s;
        text-transform: capitalize;
    }
    .selected-options .options .option:first-of-type {
        border-top: 1px solid #ced4da;
    }
    .selected-options .options .option:hover {
        background: #ced4da;
        color: white;
    }
    .selected-options .options .option:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .selected-options .options .option .opt-name{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        justify-content: center;
    }
    .selected-options .options .option .opt-name .sub-options{
        width: 120%;
        position: absolute;
        right: -120%;
        top: -1px;
        border-radius: 5px;
        border-top-left-radius: 0px;
        overflow: hidden;
        border: 1px solid #ced4da;
        max-height: 400px;
        overflow: auto;
        background: white;
    }

    .selected-options .options .option .opt-name .sub-options::-webkit-scrollbar {
        width: 10px;
    }
    .selected-options .options .option .opt-name .sub-options::-webkit-scrollbar-track {
        background: transparent;
    }
    .selected-options .options .option .opt-name .sub-options::-webkit-scrollbar-thumb {
        background: #f2f5f8;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        transition: 0.2s;
    }
    .selected-options .options .option .opt-name .sub-options::-webkit-scrollbar-thumb:hover {
        background: #e6e8eb;
        border: 2px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
    }

    .selected-options .options .option .opt-name .sub-options .sub-opt{
        padding: 10px;
        color: black;
        background: white;
        border-bottom: 1px solid #ced4da;
        transition: 0.2s;
    }
    .selected-options .options .option .opt-name .sub-options .sub-opt:hover{
        background: #ced4da;
        color: white;
    }
    .selected-options .options .option .opt-name .sub-options .sub-opt:last-of-type{
        border: none;
    }
</style>