<template>
    <div class="contentTitle">
          <h3>{{title.replace('y', 'ie')}}s</h3>
          <button class="btn" v-if="hasCreate && canCreate.includes(getUserProfile.role?.id)" @click="create = true">
            New {{title}}
          </button>
    </div>
    <div class="contentMenu">
        <ul class="navbar-nav">
            <li class="nav-item type">
                <template v-for="(tab,index) in tabFilters" :key="index">
                    <span :class="{active: isActive === index}" @click="handleTab(index, tab.filter)">{{tab.name}}</span>
                </template>
            </li>
        </ul>
        <div class="search-filters" v-if="hasFilters">
            <div class="nav-item" v-if="checked.length">
                <selected-options :items="[{name: 'delete selected'}]" @selectedItem="handleAction"/>
            </div>
            <div class="nav-item">
                <filter-by :items="toFilter" @selectedItem="filterByItem"/>
            </div>
            <div class="nav-item">
                <div class="siteSearch">
                    <div class="searchPan">
                        <i class="fa fa-search searchIcon" aria-hidden="true"></i>
                        <input type="text" class="form-control" v-model="search_key" placeholder="Search" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Table :title="title" :search_key="searchKey" :filter="filter" :entries="entries" :headItems="headItems" :hasCheck="hasCheck" :canCreate="canCreate.includes(getUserProfile.role.id)" :loaded="loaded" :isChecked="isChecked" @optionSel="handleOption" @checks="handleChecked"/>
</template>

<script>
import { mapGetters } from 'vuex'
import {
    GET_USER_PROFILE_GETTER,
} from '@/store/storeconstants';
import Table from './Table/Head.vue'
import SelectedOptions from '@/components/Ui/CRUD/SelectedOptions.vue';
import FilterBy from "@/components/Ui/General/FilterBy.vue";

export default {
    props: {
        title: {
            type: String
        },
        headItems: {
            type: Array,
            required: false,
            default: () => []
        },
        canCreate: {
            type: Array
        },
        entries: {
            type: Array,
            required: false,
            default: () => []
        },
        hasCheck: {
            type: Boolean
        },
        loaded: {
            type: Boolean
        },
        toFilter: {
            type: Array
        },
        tabFilters: {
            type: Array
        },
        isActive: {
            type: Number
        },
        hasFilters: {
            type: Boolean,
            required: false,
            default: true
        },
        hasCreate: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits: ['optionSel', 'create', 'filters', 'actions', 'search', 'selectFilter'],
    data() {
        return {
            timeout: null,
            create: false,
            isChecked: false,
            search_key: '',
            filter: [],
            checked: [],
            client: localStorage.getItem("client") ?  JSON.parse(localStorage.getItem("client")) : null,
            searchKey: '',
        };
    },
    components:{
        Table,
        SelectedOptions,
        FilterBy,
    },
    watch: {
        search_key() {
            clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.$emit('search', this.search_key)
                this.searchKey = this.search_key
            }, 1000);
        },

        create(val) {
            if(val) {
                this.$emit('create', val)
                this.create = false
            }
        },
    },
    computed:{
        ...mapGetters('account', {
            getUserProfile: GET_USER_PROFILE_GETTER,
        }),
    },
    methods:{
        startTimer() {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.getData(1), this.doneTypingInterval);
        },
        stopTimer() {
            clearTimeout(this.typingTimer);
        },

        handleAlert(data) {
            this.emitter.emit('alert', data)
        },

        handleAction(data) {
            const toSend = {
                name: data[0].name,
                data: this.checked
            }

            this.$emit('actions', toSend)
        },

        filterByItem(data) {
            this.$emit('filters', data)
        },

        handleOption(data) {
            this.$emit('optionSel', data)
        },

        handleTab(index, filter) {
            this.$emit('selectFilter', filter)
        },

        handleChecked(data) {
            this.checked = data
        }
    },
};
</script>

<style lang="scss" scoped>

.contentMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1200px) {
        margin-top: 20px;
        flex-direction: column-reverse;
        gap: 1rem;
    }

    .navbar-nav {
        @media only screen and (max-width: 1200px) {
            width: 100%;
        }
    }

    .type{
        display: flex;
        gap: 20px;
        @media only screen and (max-width: 1200px) {
            width: 100%;
            justify-content: space-between;
            margin: 0;
        }
        span{
            user-select: none;
            cursor: pointer;
            padding: 2px 0;
            font-weight: 500;
            &.active{
                font-weight: 600;
                color: var(--primary-color);
            }
        }
    }
    .search-filters {
        display: flex;
        gap: 20px;
        align-items: center;

        @media only screen and (max-width: 1200px) {
            width: 100%;
            justify-content: flex-end;
            gap: 10px;
        }
        .searchPan{
            position: relative;
            display: flex;
            align-items: center;
            .searchIcon{
                position: absolute;
                left: 10px;
                color: #CAD6E2;
            }
            .form-control{
                border: 1px solid #ced4da;
                padding: 10px 15px;
                border-radius: 5px;
                &::placeholder{
                    color: #CAD6E2;
                }
            }
         }
    }
}
</style>